<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      icon="mdi-frequently-asked-questions"
      :title="$t('QuestionBanks')"
      fill-height
      :loading="loading"


      :no-content-condition="items.length === 0"
      no-content-text="NoQuestionBanksYetMessage"
      no-content-icon="mdi-frequently-asked-questions"
      no-content-button-text="NewQuestionBank"
      :no-content-button-action="openNewBankDialog"

  >

    <template #default>

      <portal to="dash_course.header">
        <div>
          <ws-button v-if="items.length > 0" @click="openNewBankDialog" label="NewQuestionBank"></ws-button>
        </div>
      </portal>


      <ws-data-table
          :items="items"
          :headers="headers"
          hide-default-header
          class="mt-6"
          items-per-page="25"
          :footer-props="{ itemsPerPageOptions : [25,50,100,-1]}">

        <template v-slot:item="{ item , index  }">
          <v-hover v-slot="{hover}">
            <tr @click="gotoQuestionBank"
                style="cursor: pointer !important;"
                :style="`background : ${ hover ? wsLIGHTCARD : ''} !important`"
            >

              <td :style="`border-color: ${wsBACKGROUND}`">
                <router-link
                    :to="businessDashLink('courses/' + uuid + '/' + 'question_banks/edit/' + item.uuid )"
                    :style="`color : ${wsACCENT} !important`"
                    class="noUnderline" >

                  <div class="d-flex align-center linkHover">
                    <h5>{{ item.name !== ' ' ? item.name : ($t('BlankQuestionBank') + ' ' + ( index + 1 ) ) }}</h5>
                  </div>

                </router-link>
              </td>
              <td width="10px" class="text-no-wrap">
                <h5 class="text-center text-no-wrap"> {{ item.questions_count }}</h5>
              </td>

            </tr>
          </v-hover>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>
      <ws-dialog
          @save="newQuestionBank"
          v-model="displayDialog"
          :title="$t('NewQuestionBank')"
      >

        <wsTextField
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterQuestionBankName')"
        />

      </ws-dialog>
    </template>


</sub-page>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "courseQuestionBanks",
  props : {
    uuid : {
      type : String
    },
  },
  data() {
    return {
      loading : false,
      items : [],
      displayDialog : false,
      entityData : {
        name : ''
      }
    }
  },
  watch : {
    course() {
      if ( this.items.length > 0 ) {
        this.INIT_PAGE({
          icon : 'mdi-forum',
          title : this.$t('QuestionBanks'),
          button : this.$t('NewQuestionBank') ,
          action : this.openNewBankDialog
        })
      }
    },
  },
  computed : {
    ...mapState('courses', ['selectedLang','course']),

    headers() {
      return [
        { text  : this.$t('Name')      , value : 'name' },
        { text  : this.$t('QuestionsCount') , value : 'questions_count' },
      ]
    },

  },
  methods : {
    ...mapActions('tests',[
        'GET_COURSE_QUESTION_BANKS',
        'ADD_QUESTION_BANK'
    ]),
    ...mapMutations('courses' , ['INIT_PAGE']),

    openNewBankDialog() {
      this.entityData = {
        uuid : this.uuid,
        lang : this.selectedLang,
        name : ''
      }
      this.displayDialog = true
    },
    async newQuestionBank() {

      let result = await this.ADD_QUESTION_BANK(this.entityData)
      if ( !result ) {
        return
      }
      this.$router.push(this.businessDashLink('courses/'  +  this.uuid  +  '/question_banks/edit/' + result.uuid))

    },
    gotoQuestionBank() {
      this.$router.push(this.businessDashLink('courses/' + this.uuid + '/' + 'question_banks/edit/' + this.item.uuid ))
    },

    async initPage() {

      this.loading = true
      let result = await this.GET_COURSE_QUESTION_BANKS({ uuid : this.uuid, lang : this.selectedLang } )
      this.loading = false
      if ( !result || result === true ) {
        return
      }
      this.items = result

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>