import { render, staticRenderFns } from "./courseQuestionBanks.vue?vue&type=template&id=59e8879c&scoped=true&"
import script from "./courseQuestionBanks.vue?vue&type=script&lang=js&"
export * from "./courseQuestionBanks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59e8879c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VHover})
